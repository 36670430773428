/**
 * Add your own properties and values that defines your theme.
 * This file may be used at asset/tailwind.config.js, and component/Theme.tsx.
 */
module.exports = {
  theme_colors: {
    primary: "var(--primary)",
    secondary: "var(--secondary)",
  },
  system_colors: {
    info: 'var(--info)',
    success: 'var(--success)',
    error: 'var(--error)',
    warning: 'var(--warning)',
  },
  font_family: {
    body: ["Roboto", "sans-serif"],
    display: ["Roboto Slab", "serif"],
  },
};
